import React from 'react';

class ContentCard extends React.Component {
    render() {
        return <section className="module module-content-card">
            <div className="inner">
                <div className="title-lg">
                    {this.props.data.title}
                </div>

                <div className="card-info">
                    <div className="img">
                        <img src="/images/cards-poupamais@2x.png" alt="Card Poupa Mais" />
                    </div>
                    <div className="col">
                        <span className="title-lg">{this.props.data.liter}<span>L</span></span>
                        <span className="title-sm">{this.props.data.literLabel}</span>
                    </div>
                    <div className="col col-small">
                        <span>=</span>
                    </div>
                    <div className="col">
                        <span className="title-lg">{this.props.data.euros}<span>€ <span className="s2">*</span></span></span>
                        <span className="title-sm">{this.props.data.euroLabel}</span>
                    </div>
                </div>
                <div className="text-info">
                    {this.props.data.description}
                </div>
            </div>
        </section>;
    }
}

export default ContentCard;
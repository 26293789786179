import React from 'react';

class Hero extends React.Component {
    render() {
        return <section className="module module-hero">
            <div className="inner">
                <div className="img"></div>
                <div className="logos">
                    <img className="logo-1" src="/images/logos/logo-poupamais@2x.png" alt="Logo Pingo Doce" />
                    <img className="logo-3" src="/images/logos/logo-bp@2x.png" alt="Logo BP" />
                    <img className="logo-2" src="/images/logos/logo-edp@2x.png" alt="Logo EDP" />
                </div>
            </div>
        </section>;
    }
}

export default Hero;
import React from 'react';

class ContentSimple extends React.Component {
    render() {
        return <section className="module module-content-simple">
            <div className="inner">
                <h3 className="title-lg">{this.props.data.title}</h3>
                <div className="text-info">
                    {this.props.data.description}
                </div>
            </div>
        </section>;
    }
}

export default ContentSimple;
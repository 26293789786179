import React from 'react';
import './App.css';

import Client from './components/Contentful';
import { INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Form from './components/Form'
import Hero from './components/Hero'
import ContentCard from './components/ContentCard'
import ContentSimple from './components/ContentSimple'
import Footer from './components/Footer'

const options = {
    renderNode: {
        [INLINES.HYPERLINK]: (node) => {
            return <a href={node.data.uri} target="_blank" rel="noopener noreferrer">{node.content[0].value}</a>;
        }
    }
};

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cms: {
                form: '',
                hero: '',
                card: '',
                content: '',
                footer: ''
            }
        };


        // GET FROM CONTENTFUL CMS API
        Client.getEntries()
            .then(entry => {

                let cmsObj = [];

                for(let i = 0; i < entry.items.length; i++){
                    let key = entry.items[i].sys.contentType.sys.id;
                    let content = entry.items[i].fields;

                    for (let k in content){
                        if(typeof content[k] === 'object'){
                            content[k] = documentToReactComponents(content[k], options);
                        }
                    }

                    cmsObj[key] = content;
                }

                this.setState({ cms: cmsObj });
            })
            .catch(console.error);
    }

    render() {
        return <>
                <Form data={this.state.cms.form} />
                <Hero data={this.state.cms.hero} />
                <ContentCard data={this.state.cms.card} />
                <ContentSimple data={this.state.cms.content} />
                <Footer data={this.state.cms.footer} />
        </>;
    }
}

export default App;
import React from 'react';

class Footer extends React.Component {
    render() {
        return <footer className="module module-footer js-footer clearfix">
            <div className="inner">
                <div className="col">
                    {this.props.data.label}
                </div>
                <div className="col logos">
                    <img width="190" src="/images/logos/logo-bp-footer.png" alt="Logo BP" />
                </div>
            </div>
        </footer>;
    }
}

export default Footer;